.configuracion-selector-text {
    display: flex;
    font-size: large !important;
    font-weight: bold !important;
    cursor: pointer;
}

.configuracion-accordion-option-container {
    color: inherit;
    margin-left: 24px;
    padding-left: 24px;
    margin-right: 24px;
}

.configuracion-accordion-option-container:hover {
    color: inherit;
    text-decoration: none;
}

.configuracion-accordion-option-container-selected {
    border-right: solid;
    border-right-color: var(--mui-palette-serviall-main);
    background-color: var(--mui-palette-serviall-gray2);
}


.configuracion-accordion-option-text {
    font-size: var(--serviall-h6);
    font-weight: bold;
    cursor: pointer;
}


.configuracion-selected-icon {
    color: var(--mui-palette-serviall-main) !important;
}

.configuracion-options-container {
    margin-top: 50%;
    display: flex;
    flex-direction: column
}

.configuracion-content {
    padding: 5%;
    overflow: auto;
    max-height: 100%
}

.configuracion-grid-separator {
    border-right: 2px solid var(--mui-palette-serviall-gray2);
}

.configuracion-navlink {
    color: var(--mui-palette-serviall-gray1);
    text-decoration: none;
    color: none;
}

.configuracion-navlink.active {
    filter: none;
    color: var(--mui-palette-common-onBackground);
    text-decoration: none;
    color: none;
}

.configuracion-navlink:hover {
    color: var(--mui-palette-common-onBackground);
    text-decoration: none;
    color: none;
    backdrop-filter: brightness(90%);
}

@media screen and (max-width:960px) {
    .configuracion-grid-separator {
        border-right: 0;
    }
}