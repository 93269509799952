.dialog-close-button-icon {
    position: absolute !important;
    right: 8px !important;
    top: 8px !important
}

.serviall-dialog-container .MuiDialog-paper {
    border-radius: 20px;
}

.serviall-dialog-file-icon {
    width: 48px;
    height: 48px;
}