.configuracion-permisos-title-text {
    font-weight: bold !important;
    color: var(--mui-palette-serviall-main);
    font-size: x-large !important;
}

.configuracion-permisos-caption-text {
    font-size: large !important;
}

.configuracion-permisos-component-table .serviall-datagrid-header2 {
    display: flex !important;
    justify-content: center !important;
}

.configuracion-permisos-section-text {
    font-weight: bold !important;
    font-size: large !important;
    margin-bottom: 20px !important;
}

.configuracion-permisos-component-table {
    border: solid;
    height: 60%;
    border-width: 1px;
    border-radius: 10px;
    border-color: var(--mui-palette-serviall-gray2);
}

.configuracion-permisos-section-container .MuiPaper-root{
    border-radius: 10px!important;
    border: 1px
}

.configuracion-permisos-save-container {
    display: flex;
    margin-top: 5%;
    flex-direction: row-reverse;
}

.configuracion-permisos-save-button {
    text-transform: none !important;
    padding: 10px 30px 5px 30px !important;
}

.configuracion-permisos-loading-container {
    height: 300px;
    width: 100%;
    justify-content: center;
    align-items: center
}

.configuracion-permisos-loading-label {
    font-size: x-large !important;
}

.MuiTablePagination-selectLabel {
    margin-top: 10px;
}

.MuiTablePagination-displayedRows {
    margin-top: 10px;
}