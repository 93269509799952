.serviall-input-container {
    width: 100%;
    height: 100%;
    max-height: 75px;
    overflow: visible;
}

.serviall-input {
    width: 100%;
    border: solid;
    border-radius: 12px !important;
    border-width: 1px;
    font-size: 16px;
    padding: 10px;
    border-color: var(--mui-palette-serviall-main);
    background-color: var(--mui-palette-common-background);
    color: var(--mui-palette-common-onBackground);
    transition: box-shadow 0.2s;
    height: 51.6px;
}

.serviall-icon-right {
    position: relative;
    right: 35px;
    color: var(--mui-palette-serviall-main);
}

.serviall-input:focus {
    box-shadow: 0px 0px 3px 3px var(--mui-palette-serviall-main);
}

.serviall-input::placeholder {
    font-family: "Poppins";
}

.serviall-input-label {
    color: var(--mui-palette-serviall-darkGray);
    font-weight: 500 !important;
    font-size: large !important;
}

.serviall-input-caption {
    color: var(--mui-palette-serviall-gray1);
    font-weight: 500 !important;
    font-size: small !important;
}

.serviall-input-error {
    text-align: end;
    font-size: small !important;
    color: var(--mui-palette-error-main);
}

.serviall-input:focus {
    outline: none !important;
}

.serviall-column-selector {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    align-self: flex-end;
}

.serviall-column-selector .MuiInputBase-formControl > .MuiSelect-iconOutlined, .serviall-column-selector .MuiInputBase-formControl > .MuiInputBase-input .MuiOutlinedInput-input{
    color: var(--mui-palette-action-active) !important;
}