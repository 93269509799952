.gestorOportunidades-data-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gestorOportunidades-actions-container {
    display: flex;
    justify-content: flex-end;
}

.gestorOportunidades-download-button {
    text-transform: none !important;    
    padding: 10px !important;
}

.gestorOportunidades-icon {
    margin: 5px;
    color: var(--mui-palette-serviall-main);
    cursor: pointer;
}

.gestorOportunidades-datagrid-action-icon {
    color: var(--mui-palette-serviall-main);
    cursor: pointer;
}

/* Only use in non MUI icons */
.gestorOportunidades-action-icon {
    width: 24px;
    height: 24px;
}

.gestorOportunidades-datagrid-status-container {
    display: "flex";
    align-items: "center"
}

.gestorOportunidades-visibility-icon-ok {
    color: var(--mui-palette-success-main);
}

.gestorOportunidades-visibility-icon-pend {
    color: var(--mui-palette-serviall-main);
}

.gestorOportunidades-visibility-icon-cm {
    color: var(--mui-palette-warning-light);
}