/* Typography size variables */
:root {
    --serviall-h1: 48.83px;
    --serviall-h2: 39.06px;
    --serviall-h3: 31.25px;
    --serviall-h4: 25.00px;
    --serviall-h5: 20.00px;
    --serviall-h6: 16.00px;
    --serviall-md: 14px;
    --serviall-small: 12.80px;
    --serviall-tiny: 10.20px;
    --serviall-extra-tiny: 7.70px;
}
/* Font declaration */
@font-face {
    font-family: "MyriadProBold";
    src: local("MyriadProBold"),
        url("./assets/fonts/Myriad Pro Bold.ttf") format("truetype");
}

@font-face {
    font-family: "VerminVibes2";
    src: local("VerminVibes2"),
        url("./assets/fonts/Vermin Vibes 2.otf") format("opentype");
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("./assets/fonts/Poppins-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("./assets/fonts/Poppins-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 100 300;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("./assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}

/* All <Typography/> tags will use the Poppins font */
.MuiTypography-root {
    font-family: "Poppins" !important;
    font-size: var();
}