.versions-dialog-title.MuiTypography-root {
    font-size: var(--serviall-h5);
    color: var(--mui-palette-serviall-main);
}

.versions-icon {
    cursor: pointer;
}

.versions-icon-disabled {
    color: var(--mui-palette-serviall-gray2)
}

.versions-select-container .MuiOutlinedInput-root , .versions-select-container .MuiInputBase-root{ 
    width: 100%;
    border: 1px solid !important;
    border-radius: 12px !important;
    border-width: 1px;
    font-size: 16px;
    padding: 10px;
    border-color: var(--mui-palette-serviall-main) !important;
    background-color: var(--mui-palette-common-background);
    color: var(--mui-palette-common-onBackground);
    transition: box-shadow 0.2s;
    height: 51.6px;
}

.versions-select-container fieldset{
   border: none;
}
