.serviall-actions-menu-text {
    display: flex;
    align-items: center;
    white-space: normal;
    color: var(--mui-palette-serviall-gray1);
}

.serviall-actions-menu-icon {
    color: var(--mui-palette-serviall-main);
}

.serviall-actions-container {
    width: fit-content;
}