.detalleHomologoCliente-title.MuiTypography-root {
    font-size: var(--serviall-h5);
    font-weight: 500;
}


/* Applied to what's inside of a header cell */
.detalleHomologoCliente-datagrid-col-container {
    display: flex;
    height: 100px;
    margin-top: 4px;
    justify-content: space-between;
    white-space: normal;
    word-wrap: break-word;
    align-items: center;
}

.detalleHomologoCliente-datagrid-cols-text.MuiTypography-root {
    margin-top: 8px;
    font-size: var(--serviall-small);
    color: var(--mui-palette-serviall-main);
}

.detalleHomologoCliente-datagrid-cols-reset-text.MuiTypography-root {
    cursor: pointer;
    font-size: var(--serviall-h6);
    color: var(--mui-palette-serviall-gray1);
}

.detalleHomologoCliente-datagrid-row {}

.detalleHomologoCliente-datagrid-header-icon {
    cursor: pointer;
}

.detalleHomologoCliente-input-icon {
    position: relative;
    right: 30px;
    cursor: pointer;
    border-left: 1px solid;
}