.itemsLicitacion-title.MuiTypography-root {
    font-size: var(--serviall-h5);
    font-weight: 500;
    
}

.itemsLicitacion-warning.MuiTypography-root {
    font-size: var(--serviall-small);
    color: var(--mui-palette-serviall-midRed2);
    font-weight: 500;
}

.itemsLicitacion-toggle-button.MuiToggleButton-root {
    text-transform: none;
    color: var(--mui-palette-common-onBackground);
    background-color: var(--mui-palette-serviall-background);
    border-color: var(--mui-palette-serviall-main);
    border-radius: 10px;
    min-height: 51.6px;
    align-self: flex-end;
    font-family: Poppins;
    
}

.itemsLicitacion-toggle-button.MuiToggleButton-root.Mui-selected {
    background-color: var(--mui-palette-serviall-midRed2);
    color: var(--mui-palette-serviall-selectDate)
}

.itemsLicitacion-toggle-button.MuiToggleButton-root.Mui-selected:hover {
    background-color: var(--mui-palette-serviall-midRed2);
}


/* Applied to datagrid header classname property */
.itemsLicitacion-datagrid-header {}

/* Applied to what's inside of a header cell */
.itemsLicitacion-datagrid-col-container {
    display: flex;
    height: 100px;
    margin-top: 4px;
    justify-content: space-between;
    white-space: normal;
    word-wrap: break-word;
}

/* Applied to each cell of a column */
.itemsLicitacion-datagrid-checkbox-cell {
    display: flex;
    justify-content: center;
}

.itemsLicitacion-datagrid-cols-text.MuiTypography-root {
    margin-top: 8px;
    font-size: var(--serviall-tiny);
    color: var(--mui-palette-serviall-main);
}

.itemsLicitacion-datagrid-cols-reset-text.MuiTypography-root {
    cursor: pointer;
    font-size: var(--serviall-small);
    color: var(--mui-palette-serviall-gray1);
}

.itemsLicitacion-datagrid-row {}

.itemsLicitacion-datagrid-row-igual-selected {
    background-color: var(--mui-palette-serviall-gray1);
}

.itemsLicitacion-datagrid-row-alternativo-selected {
    background-color: var(--mui-palette-serviall-gray2);
}

.itemsLicitacion-datagrid-row-repeated_item{
    background-color: var(--mui-palette-warning-main);
}

.itemsLicitacion-datagrid-row-extra_item {
    background-color: var(--mui-palette-serviall-midRed2);
}

.itemsLicitacion-datagrid-header-icon {
    cursor: pointer;
}

.itemsLicitacion-input-icon {
    position: relative;
    right: 30px;
    cursor: pointer;
    border-left: 1px solid;
}

.itemsLicitacion-bottom-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemsLicitacion-no-match-text.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 700;
}

.itemsLicitacion-radio-label {
    font-size: var(--serviall-tiny);
    font-weight: 700;
}

.itemsLicitation-pagination-show{
    display: flex;
    justify-content: center;
    
}

.itemsLicitation-button{
    background-color: transparent;
}


@media screen and (min-width: 480px) {
    .itemsLicitation-pagination-show{
        display: none;
    }
}