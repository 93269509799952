.configuracion-cuenta-title-text {
    font-weight: bold !important;
    color: var(--mui-palette-serviall-main);
    font-size: x-large !important;
}

.configuracion-cuenta-caption-text {
    font-size: large !important;
}

.configuracion-cuenta-grid-container {
    height: 70%;
    margin-top: 5%;
}

.configuracion-cuenta-left-grid-container {
    display: flex;
    flex-direction: column !important;
    padding-right: 4%;
}

.configuracion-cuenta-right-grid-container {
    display: flex;
    flex-direction: column !important;
    padding-right: 4%;
}


.configuracion-cuenta-section.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 600;
    color: var(--mui-palette-serviall-main);
}