.VerDetalleMaestros-property-title {
    font-weight: 500 !important;
    font-size: large !important;
    color: var(--mui-palette-serviall-darkGray);
}

.VerDetalleMaestros-property-value {
    font-weight: 500 !important;
    color: var(--mui-palette-serviall-gray1);
}

.VerDetalleMaestros-grid-left-content {
    padding-right: 30px;
}

.VerDetalleMaestros-grid-left-content-box {
    padding: 20px;
}

.VerDetalleMaestros-grid-right-help-list-container {
    margin-left: 10px !important;
}

.VerDetalleMaestros-grid-right-help-text{
    text-align: justify;
}

.VerDetalleMaestros-grid-right-help-dot-icon {
    width: 16px !important;
}

.VerDetalleMaestrsos-grid-separator {
    border-right: 2px solid var(--mui-palette-serviall-gray2);
    
}

@media screen and (max-width: 640px) {
    .VerDetalleMaestrsos-grid-separator {
        border: none;
    }
}