.serviall-upload-button {
    background-color: var(--mui-palette-common-onBackground) !important;
    color: var(--mui-palette-common-background) !important;
    border-radius: 12px !important;
    padding: 10px !important;
    width: fit-content !important;
    height: 100%;
    min-height: 51.6px;
}

.serviall-upload-button.Mui-disabled {
    color: var(--mui-palette-serviall-darkGray) !important
}

.serviall-upload-button:hover {
    background-color: "#525252"
}

.serviall-upload-primary-text {
    font-size: 18px;
}

.serviall-upload-secondary-text {
    text-transform: none;
    font-size: small !important;
}

.serviall-upload-terciary-text {
    font-size: small !important
}

.upload-file-window {
    position: absolute;
    padding: 20px;
    top: 50%;
    left: 50%;
    width: fit-content;
    background-color: white;
    box-shadow: 24;
}