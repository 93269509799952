.calendarioPage-actions-container {
    display: flex;
    justify-content: space-between;
}

.calendarioPage-search-input {
    border-radius: 10px !important;
    border-color: var(--mui-palette-serviall-main);
}


.calendarioPage-actions-button {
    text-transform: none !important;
    padding: 10px !important;
    height: 51.6px !important;
}

.calendarioPage-calendar-container {
    display: flex;
    justify-content: center;
}

.calendarioPage-calendar {
    /* This changes the overall calendar, maybe use this to add a box-shadow or a bg color to the whole calendar */
    color: var(--mui-palette-common-onBackground);
    font-family: "Poppins";
}

.calendarioPage-navigation-button {
    text-transform: none !important;
    border-radius: 10px !important;
    background-color: var(--mui-palette-serviall-darkGray) !important;
    color: var(--mui-palette-common-background) !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    height: 40px !important;
}

.calendarioPage-navigation-buttonGroup-left {
    border-radius: 10px 0px 0px 10px !important;
}

.calendarioPage-navigation-buttonGroup-right {
    border-radius: 0px 10px 10px 0px !important;
}

.calendarioPage-calendar-tile {
    height: 70px;
    border-radius: 5px;
    border: none;
    font-size: large;
    color: var(--mui-palette-common-onBackground);
    background-color: var(--mui-palette-common-background);
}

.calendarioPage-calendar-tile:hover {
    background-color: var(--mui-palette-serviall-lightGray);
}

.calendarioPage-calendar-tile-selected {
    color: var(--mui-palette-common-background);
    /* See how this changes with theme */
    background: var(--mui-palette-serviall-gradient1);
}

.calendarioPage-licitation-display-id-icon {
    color: var(--mui-palette-serviall-main) !important;
}

.calendarioPage-typography-semibold {
    font-weight: 600 !important;
}

.calendarioPage-no-date-selected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.calendarioPage-no-date-selected-typography {
    color: var(--mui-palette-serviall-darkGray);
    font-size: x-large !important;
    text-align: center;
}


.active{
    filter: brightness(55%)
}

.calendarioPage-grid-separator{
    border-bottom: 2px solid var(--mui-palette-serviall-gray2);
    width: 100%;
    margin-bottom: 1rem;
}

.react-calendar__tile{
    position: relative !important;
}

.react-calendar-dots-container{
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    display: flex; 
    justify-content: center;
    gap: 3px;
    padding-right: 10px;
    
}

.red-dot{
    background-color: red;
}

.blue-dot{
    background-color: rgb(44, 2, 149);
}

.react-calendar-dot{
    width: 10px;
    height: 10px;
    border-radius: 50%; 

}

.react-calendar-dot2{
    width: 10px;
    height: 10px;
    border-radius: 50%; 
}
.react-calendar-dot3{
    width: 10px;
    height: 10px;
    border-radius: 50%; 
}


@media screen and (min-width: 960px ){
    .calendarioPage-grid-separator{
        margin-bottom: 0;
        margin-left: 1rem;
        width: 2px;
        border-right: 2px solid var(--mui-palette-serviall-gray2);
        border-bottom: 0;
    }
}