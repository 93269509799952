.vistaAgregada-redirectIcon:hover{
    cursor:pointer;
}

.vistaAgregada-redirectIcon-disable{
    color: #e0e0de;
    cursor: not-allowed;

}

.vistaAgregada-dialog-title.MuiTypography-root {
    font-size: var(--serviall-h5);
    color: var(--mui-palette-serviall-main);
}