.maestroProveedores-data-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px !important;
}

.maestroProveedores-subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.maestroProveedores-actions-container {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    margin-bottom: 1%;
    justify-content: space-between;
    align-items: center;
}

.maestroProveedores-actions-nuevoProveedor {
    display: flex;
    align-items: center;
    font-size: var(--serviall-h6) !important;
    color: var(--mui-palette-serviall-gray1) !important;
    cursor: pointer;
}

.maestroProveedores-actions-downloadButton {
    text-transform: none !important;
    padding: 10px !important;
}

.maestroProveedores-plus-icon {
    width: 36px;
    height: auto;
    color: "red"
}

/* Only use in non MUI icons */
.maestroProveedores-action-icon {
    width: 24px;
    height: 24px;
}

.maestroProveedores-datagrid-container {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    height: 100%;
    max-height: 500px;
    overflow-x: auto;
    width: fit-content;
    max-width: 80vw;
}

.maestroProveedores-datagrid-action-icon {
    color: var(--mui-palette-serviall-main);
    cursor: pointer;
}