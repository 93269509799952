.configuracionMantenedoresModelos-formControl path{
    color: black !important;
    background-color: black !important;
}

.configuracionMantenedoresModelos-grid{
    border-radius: 2rem;
}
.configuracionMantenedoresModelos-grid th:nth-child(1), .configuracionMantenedoresModelos-grid th:nth-child(3), .configuracionMantenedoresModelos-grid th:nth-child(5){
    background-color: var(--mui-palette-serviall-gray1);
    color: var(--mui-palette-common-background);
    font-weight: normal;
    font-family: "Poppins";
    margin: 0 auto;
    
}

.configuracionMantenedoresModelos-grid th:nth-child(2), .configuracionMantenedoresModelos-grid th:nth-child(4){
    background-color: var(--mui-palette-serviall-darkGray);
    color: var(--mui-palette-common-background);
    font-weight: normal;
    font-family: "Poppins";
    margin: 0 auto;
    font-weight: bold;
    
}

th:nth-child(3), .configuracionMantenedoresModelos-grid th span{
    margin: 0 auto;
}

.configuracionMantenedoresModelos-grid .MuiPaper-root, .configuracionMantenedoresModelos-grid .TableContainer-root{
    border-radius: 10px!important;
    border: 1px
}

.configuracionMantenedoresModelos-grid .MuiTableRow-root{
    background-color: var(--mui-palette-serviall-gridRow);
}
