.serviall-step-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.serviall-step-number-circle-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: solid;
    border-width: 2px;
    border-radius: 100%;
    border-color: var(--mui-palette-serviall-main);
}

.serviall-step-number-text {
    font-size: large !important;
    font-weight: bold !important;
}

.serviall-step-text {
    font-size: medium !important;
    color: var(--mui-palette-serviall-gray1) !important;
}

.serviall-step-icon {
    color: var(--mui-palette-serviall-main);
    cursor: pointer;
}