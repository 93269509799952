.draggable-input{
    position: absolute;
    top: 60px;
    height: 30px;
    width: 100%;
    line-height: 1em;
    left: 0;
    color: #757575;
    font-weight: 400 !important;
    font-family: "Poppins" !important;
}

.draggable-button{
    position: absolute;
    top: 60px;
    height: 30px;
    width: 100%;
    left:0;
    line-height: 1em;
    padding: 0px;   
    color: #757575;
    font-weight: 400 !important;
    font-family: "Poppins" !important;

}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}