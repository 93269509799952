.login-container {
    height: 100%;
    min-height: 100vh;
    /*background-image: url("../../assets/Images/fondo\ pepe\ escritorio.png");*/
    background-color: #d01318;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    /*background-color: var(--mui-palette-serviall-background);*/
}

.login-config-container {
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    right: 20px;
}

.login-title {
    margin: 20px !important;
    /* Some properties need the important keyword depending on MUI component*/
}

.login-form-container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: solid;
    border-color: #b8bcc0;
    max-width: 100%;
    background-color: var(--mui-palette-common-background);
    padding-bottom: 36px;
    align-items: center;
}

.login-form-fields-container {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.login-text-field-username {
    margin-bottom: 30px !important;
}

.login-text-field-password {
    margin-bottom: 20px !important;
}

.login-button-icon {
    padding-bottom: 5px;
    margin-right: -8px;
}

.login-button {
    width: 80%;
    margin: 10px 10px 30px 10px !important;
}

.login-button:disabled {
    color: rgb(212, 212, 212) !important;
    background-color: gray !important;
}

.login-loading-box {
    padding-bottom: 30px
}