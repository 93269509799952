.nuevaOportunidad-menuItem.MuiMenuItem-divider {
    border-color: var(--mui-palette-serviall-gray2);
    border-width: 2px;
}

.homePage-date-input{
    width: 100%;
}

.homePage-shadow .MuiSelect-select{
    padding: 0 8px;
}

.homePage-input-container .MuiInputBase-formControl,  .homePage-shadow .MuiInputBase-root {
    width: 100%;
    height: 30px !important;
    border: 1px solid var(--mui-palette-serviall-main) !important;
    border-radius: 8px !important;
    font-size: var(--serviall-md) !important;
    background-color: var(--mui-palette-common-background);
    color: var(--mui-palette-common-onBackground);
    display: flex;
    transition: box-shadow 0.2s;
}

.homePage-shadow{
    -webkit-box-shadow: 4px 4px 5px 0px rgba(209,209,209,1);
-moz-box-shadow: 4px 4px 5px 0px rgba(209,209,209,1);
box-shadow: 4px 4px 5px 0px rgba(209,209,209,1);
    }

.homePage-charts-title{
    font-weight: 600 !important ;
}

.homePage-chart-kpiText{
    font-size: 12px;
}

.homePage-chart-kpiNumber{
    font-weight: 600;
}

.homePage-chart-kpiPercentage{
    font-size: 10px;
}

.homePage-chart-kpiTotal:nth-of-type(2n){
    color: red;
}

