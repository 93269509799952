.dashboard-page-container {
    height: 100%;
    width: 100%;
    max-width: 100vw !important;
}

.dashboard-options-container {
    padding: 36px 0px 36px 0px;
}

.dashboard-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid;
    padding: 24px;
}


.dashboard-avatar-icon {
    background-color: var(--mui-palette-serviall-main) !important;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.dashboard-list-container .MuiButtonBase-root {
    padding: 0;

}

.dashboard-list-container .MuiList-root,
.dashboard-list-container .MuiList-padding {
    background-color: var(--mui-palette-serviall-menuBackground);
}

.dashboard-list-container .MuiCollapse-wrapper {
    padding-top: 1rem;
    padding-left: 1rem;

}

.dashboard-list-button .MuiListItemText-root {
    padding: 0.5rem 1rem;
}

.dashboard-list-text {
    word-break: break-all;
    white-space: normal;
}

.dashboard-avatar-icon>img {
    filter: brightness(0) invert(1);
    width: 60%;

}

.dashboard-date {
    font-weight: 500 !important;
}

.dashboard-sidebar-container {
    background-color: var(--mui-palette-serviall-main);
    flex-shrink: 0;
}

.dashboard-serviall-logo {
    height: auto;
    width: auto;
    margin: 70px 36px 0px 36px;
    min-width: 80px;
    filter: brightness(0) invert(1);
}


.dashboard-routes-container {
    background-color: var(--mui-palette-serviall-background);
    padding: 20px;
    width: 1000px;
    height: 100vh;
}

.dashboard-avatar-group-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.dashboard-menu-icon:hover {
    cursor: pointer;
}

.dashboard-close-icon {
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
}

.dashboard-responsive-sidebar-container {
    position: fixed;
    background-color: var(--mui-palette-serviall-main);
    width: 300px;
    z-index: 10;
    height: 100vh;
    transform: translateX(-300px);
    color: white;
    transition: all 0.5s ease-out;
    overflow-y: auto;
}

.visible {
    transform: translateX(0);
}

.no-visible-main-desktop {
    display: none !important;
}

.full-with-desktop-dashboard-routes-container {
    min-height: 100vh;
    height: 100%;
    flex-basis: 100% !important;
    max-width: 100% !important;
    padding: 20px;
}

.dashboard-account-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative
}

.dashboard-account-notification {
    background-color: white;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    bottom: -5px;
    right: -5px;
    color: black;
    display: flex;
    justify-content: center;
}

.dashboard-outlet-container {
    margin-top: 5px;
    border-radius: 20px;
    padding: 30px;
    height: 92%;
    background-color: var(--mui-palette-common-background);
    overflow-y: auto;
}

/* MUI specific classnames */
.dashboard-option-buttons.MuiToggleButtonGroup-vertical {
    width: 100%;
}

.dashboard-option-button.MuiToggleButton-root {
    border: none;
    text-transform: none;
    font-family: "Poppins";
    font-size: medium;
    color: white;

}

.dashboard-option-button.MuiToggleButton-root.Mui-selected {
    border-right: 8px solid white;
    color: #c27a7a;
}

.dashboard-option-text {
    font-size: var(--serviall-h5) !important;
    font-weight: 500 !important;
}

.dashboard-toggle-icon-img {
    width: 28px;
    filter: brightness(0) invert(1);

}

.dashboard-box-container {
    margin-left: 28px;
    margin-top: 20px;
    display: flex;
    gap: 1rem;
}

@media only screen and (max-width: 960px) {
    .dashboard-routes-container {
        min-height: 100vh;
        height: 100%;
    }
}

.dashboard-navlink {
    border: none;
    text-transform: none;
    font-family: "Poppins";
    font-size: medium;
    padding: 11px;
    color: var(--mui-palette-common-background);
    text-decoration: none;
}

.dashboard-navlink:focus {
    text-decoration: none;
    color: var(--mui-palette-common-background);
}

.dashboard-navlink:hover {
    color: var(--mui-palette-common-background);
    backdrop-filter: brightness(80%);
    text-decoration: none;
}

.dashboard-navlink.active {
    border-right: 8px solid var(--mui-palette-common-background);
    color: #c27a7a;
    background-color: rgba(var(--mui-palette-text-primaryChannel) / var(--mui-palette-action-selectedOpacity));
    text-decoration: none;
    filter: none;
}