.maestroClientes-data-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px !important;
}

.maestroClientes-subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.maestroClientes-actions-container {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    margin-bottom: 1%;
    justify-content: space-between;
    align-items: center;
}

.maestroClientes-actions-nuevoCliente {
    display: flex;
    align-items: center;
    font-size: var(--serviall-h6) !important;
    color: var(--mui-palette-serviall-gray1) !important;
    cursor: pointer;
}

.maestroClientes-actions-downloadButton {
    text-transform: none !important;
    padding: 10px !important;
}

.maestroClientes-plus-icon {
    width: 36px;
    height: auto;
    color: "red"
}

/* Only use in non MUI icons */
.maestroClientes-action-icon {
    width: 24px;
    height: 24px;
}

.maestroClientes-datagrid-action-icon {
    color: var(--mui-palette-serviall-main);
    cursor: pointer;
}