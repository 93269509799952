.agregarCliente-subtitle-container {
    display: flex;
    flex-direction: row;
}

.agregarCliente-grid-content {
    display: flex;
    flex-direction: column !important;
    align-items: center;
}

.agregarCliente-fileLangSelector {
    width: fit-content;
    border-radius: 10px;
    align-items: center;
}

.agregarCliente-grid-separator {
    border-left: 2px solid var(--mui-palette-serviall-gray2);
    margin: 0 1rem;
}

@media screen and (max-width: 640px ){
    .agregarCliente-grid-separator {
        border-right: 0;
        border-bottom: 2px solid var(--mui-palette-serviall-gray2);
    }
}   