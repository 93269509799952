.verDetalleOportunidad-paper-container {
    padding: 32px ;
}

@media only screen and (max-width: 600px) {
    .verDetalleOportunidad-paper-container {
        padding: 10px ;
    }
}

.verDetalleOportunidad-client-name-container {
    display: flex;
    justify-content: space-between
}


.verDetalleOportunidad-kpiTitle.MuiTypography-root {
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    overflow-wrap: break-word;
    margin-bottom: 1rem;
}

.verDetalleOportunidad-kpiDescription.MuiTypography-root {
    word-break: break-all;
}

.verDetalleOportunidad-actions-container {
    border-left: solid;
    height: 18vh;
    border-color: var(--mui-palette-serviall-gray2);
    border-width: 2px;
}

.verDetalleOportunidad-download-container {
    display: flex;
    align-items: center;
}

.verDetalleOportunidad-download-icon {
    color: var(--mui-palette-serviall-main);
    cursor: pointer;
}



.verDetalleOportunidad-KpiBox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verDetalleOportunidad-KpiBox-tooltip {
    align-self: flex-end;
    cursor: pointer;
}

.verDetalleOportunidad-KpiBox-container-title.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.verDetalleOportunidad-KpiBox-number.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 700;
}

.verDetalleOportunidad-KpiBox-number-tiny.MuiTypography-root {
    font-size: var(--serviall-h6);
    font-weight: 700;
}

.verDetalleOportunidad-KpiBox-text.MuiTypography-root {
    font-size: var(--serviall-small);
    text-align: center;
}

.verDetalleOportunidad-KpiBox-text-tiny.MuiTypography-root {
    font-size: var( --serviall-tiny);
    text-align: center;
}

.verDetalleOportunidad-KpiBox-group-container-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.verDetalleOportunidad-KpiBox-group-container-2 {
    width: 100%;
    display: flex;
    justify-content: space-around
}

.verDetalle-grid-separator {
    border-right: 2px solid var(--mui-palette-serviall-gray2);
}

@media screen and (max-width: 1280px) {
    .verDetalle-grid-separator {
        border-right: none;
        border-bottom: 2px solid var(--mui-palette-serviall-gray2);
    }

}