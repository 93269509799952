.nuevaOportunidad-simpleItem-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 4rem;
}

.nuevaOportunidad-download-template {
    /*text-align: right;*/
    color: var(--mui-palette-serviall-main);
    /*position: absolute;
    right: 0;*/
    margin-left: -12em;
}

.nuevaOportunidad-input {
    width: 100%;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-left: 10px;
    border-color: var(--mui-palette-serviall-main)
}

.nuevaOportunidad-families-box {
    width: 100%;
    height: 100px;
    overflow-y: auto;
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    border-color: var(--mui-palette-serviall-main)
}

.nuevaOportunidad-fileLangSelector {
    width: fit-content;
    border-radius: 10px;
    align-items: center;
}

.nuevaOportunidad-select-item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.nuevaOportunidad-select-options-title {
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: solid;
    border-color: var(--mui-palette-serviall-gray2);
    border-width: 2px;
}


.serviall-input-new-opportunity {
    width: 100%;
    border: 1px solid var(--mui-palette-serviall-main) !important;
    border-radius: 12px !important;
    font-size: 16px !important;
    padding: 14.5px 10px;
    background-color: var(--mui-palette-common-background);
    color: var(--mui-palette-common-onBackground);
    transition: box-shadow 0.2s;
}



.nuevaOportunidad-menuItem.MuiMenuItem-divider {
    border-color: var(--mui-palette-serviall-gray2);
    border-width: 2px;
}

/* DatePicker */
.nuevaOportunidad-datepicker-container .MuiInputBase-root {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 51.6px
}

.nuevaOportunidad-date-input {
    width: 100%;
}

/* Family, Opportunity Type, Assigned Exec containers */
.nuevaOportunidad-mui-input-container .MuiInputBase-root,
.datalist-container {
    height: 51.6px !important;
    display: flex !important;
    align-items: center !important;
}

.nuevaOportunidad-mui-input-container .MuiInputBase-formControl,
.nuevaOportunidad-datepicker-container .MuiInputBase-formControl,
.datalist-container input {
    width: 100%;
    border: 1px solid var(--mui-palette-serviall-main) !important;
    border-radius: 12px !important;
    font-size: var(--serviall-md) !important;
    padding: 14.5px 10px;
    background-color: var(--mui-palette-common-background);
    color: var(--mui-palette-common-onBackground);
    display: flex;
    transition: box-shadow 0.2s;
}

.desc-selectInput_container .MuiInputBase-root input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}