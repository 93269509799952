.AddAuxItemDialog-dialog-title.MuiTypography-root {
    font-size: var(--serviall-h4);
    color: var(--mui-palette-serviall-main);
}

.currency-input {
    padding: 0px 12px;
    border: 1px solid var(--mui-palette-serviall-gray2);
    border-radius: 2px;
    box-sizing: border-box;
    width: 100% !important;
    height: 40px !important;
    outline: none;
    background-color: var(--mui-palette-serviall-currencyInputBackground);
}

.currency-input::placeholder{
    color: var(--mui-palette-serviall-currencyInputPlaceholder) !important;
    font-family: inherit;
}

.currency-input:focus{
    border: 2px solid var(--mui-palette-serviall-currencyInputFocus) !important;
}
.currency-input:hover{
    border: 1px solid var(--mui-palette-serviall-currencyInputHover);
}